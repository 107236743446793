<template>
  <v-container class="pa-4">
    <v-form ref="form" lazy-validation v-model="valid">
      <v-row dense>
        <v-col cols="12" xl="3" lg="3" md="3" sm="6">
          <v-autocomplete
            label="Tipo de taxa"
            dense
            outlined
            required
            :rules="[required]"
            :items="['Taxa média', 'Faixa etária']"
            :disabled="isEditable"
            v-model="plan.planTableType"
          />
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="3" sm="6">
          <v-autocomplete
            label="Cobrança"
            dense
            outlined
            required
            :rules="[required]"
            :items="['Mês cheio', 'Pro rata']"
            :disabled="isEditable"
            v-model="plan.billingType"
          />
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="6">
          <v-switch
            label="Elegibilidade"
            hide-details
            class="ml-2 mt-1"
            :disabled="isEditable"
            v-model="plan.hasEligibility"
          />
        </v-col>
      </v-row>
      <v-row
        dense
        v-permission="{
          roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
          operator: '==='
        }"
      >
        <v-col cols="12" xl="4" lg="4" md="4" sm="6">
          <BasePlans
            label="Selecione o plano"
            required
            :rules="[required]"
            :contractId="$route.params.id"
            v-model="plan.planSelected"
          />
        </v-col>
        <v-col
          cols="12"
          xl="2"
          lg="2"
          md="2"
          sm="6"
          v-if="plan.planTableType === 'Taxa média'"
        >
          <BaseMoney
            label="Valor"
            dense
            required
            :rules="[required]"
            v-model="plan.value"
          />
        </v-col>
        <v-col cols="12" xl="6" lg="6" md="6" sm="6" v-if="plan.hasEligibility">
          <v-combobox
            label="Cargo"
            dense
            outlined
            multiple
            small-chips
            required
            :rules="[required]"
            v-model="plan.positions"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    Pressione <kbd>enter</kbd> para adicionar um novo cargo
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-combobox>
        </v-col>
        <v-col
          cols="12"
          xl="2"
          lg="2"
          md="2"
          sm="6"
          v-if="contract.situation !== 'Cancelado'"
        >
          <BaseButton
            @click="addPlan"
            color="primary"
            title="Adicionar"
            height="40"
            :disabled="!valid"
          />
        </v-col>
      </v-row>
    </v-form>

    <v-form
      ref="formList"
      lazy-validation
      v-model="validList"
      @submit.prevent="save"
    >
      <BaseTableList title="Listagem de planos">
        <template v-slot:table>
          <v-data-table
            disable-sort
            hide-default-footer
            dense
            item-key="id"
            :headers="filteredHeaders"
            :items="plan.plans"
          >
            <template
              v-slot:[`item.planName`]="{ item }"
              v-if="plan.planTableType === 'Faixa etária'"
            >
              {{ item.planName }}
              <v-tooltip bottom v-if="item.positions">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="primary" small>
                    fa-solid fa-circle-info
                  </v-icon>
                </template>
                <span>Cargos associados: {{ item.positions }}.</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.value`]="{ item }">
              {{ formatMoney(item.value) }}
            </template>

            <template v-slot:[`item.externalPlanCode`]="{ item }">
              <v-text-field dense v-model="item.externalPlanCode" />
            </template>

            <template v-slot:[`item.priceLives0To18`]="{ item }">
              <BaseMoney
                dense
                :outlined="false"
                required
                :rules="[required]"
                v-model="item.priceLives0To18"
              />
            </template>

            <template v-slot:[`item.priceLives19To23`]="{ item }">
              <BaseMoney
                dense
                :outlined="false"
                required
                :rules="[required]"
                v-model="item.priceLives19To23"
              />
            </template>

            <template v-slot:[`item.priceLives24To28`]="{ item }">
              <BaseMoney
                dense
                :outlined="false"
                required
                :rules="[required]"
                v-model="item.priceLives24To28"
              />
            </template>

            <template v-slot:[`item.priceLives29To33`]="{ item }">
              <BaseMoney
                dense
                :outlined="false"
                required
                :rules="[required]"
                v-model="item.priceLives29To33"
              />
            </template>

            <template v-slot:[`item.priceLives34To38`]="{ item }">
              <BaseMoney
                dense
                :outlined="false"
                required
                :rules="[required]"
                v-model="item.priceLives34To38"
              />
            </template>

            <template v-slot:[`item.priceLives39To43`]="{ item }">
              <BaseMoney
                dense
                :outlined="false"
                required
                :rules="[required]"
                v-model="item.priceLives39To43"
              />
            </template>

            <template v-slot:[`item.priceLives44To48`]="{ item }">
              <BaseMoney
                dense
                :outlined="false"
                required
                :rules="[required]"
                v-model="item.priceLives44To48"
              />
            </template>

            <template v-slot:[`item.priceLives49To53`]="{ item }">
              <BaseMoney
                dense
                :outlined="false"
                required
                :rules="[required]"
                v-model="item.priceLives49To53"
              />
            </template>

            <template v-slot:[`item.priceLives54To58`]="{ item }">
              <BaseMoney
                dense
                :outlined="false"
                required
                :rules="[required]"
                v-model="item.priceLives54To58"
              />
            </template>

            <template v-slot:[`item.priceLives59To120`]="{ item }">
              <BaseMoney
                dense
                :outlined="false"
                required
                :rules="[required]"
                v-model="item.priceLives59To120"
              />
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-tooltip bottom v-if="contract.situation !== 'Cancelado'">
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    color="error"
                    small
                    @click="remove(item)"
                    v-permission="{
                      roles: [
                        'A1',
                        'AC1',
                        'AC2',
                        'AC3',
                        'ACI1',
                        'ACI2',
                        'ACI3'
                      ],
                      operator: '==='
                    }"
                  >
                    fa-regular fa-trash-can
                  </v-icon>
                </template>
                <span>Excluir</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </template>
      </BaseTableList>

      <v-divider class="my-5" />

      <v-row>
        <v-col cols="12" sm="8">
          <template v-if="contract.situation !== 'Cancelado'">
            <BaseButton
              outlined
              id="btn-history"
              name="btn-history"
              color="error darken-1"
              title="Aplicar reajuste"
              @click="history(false)"
              v-permission="{
                roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
                operator: '==='
              }"
            />
          </template>

          <BaseButton
            outlined
            id="btn-view-history"
            name="btn-view-history"
            color="primary"
            title="Visualizar histórico"
            @click="history(true)"
            v-if="plan.hasPlanReadjustmentHistory"
          />
        </v-col>
        <v-col cols="12" sm="4" class="text-right">
          <BaseButton
            outlined
            id="btn-back"
            name="btn-back"
            color="primary"
            title="Voltar"
            @click="back"
          />

          <template v-if="contract.situation !== 'Cancelado'">
            <BaseButton
              id="btn-save"
              name="btn-save"
              type="submit"
              color="primary"
              title="Salvar"
              :disabled="!validList"
              v-if="plan.plans.length"
              v-permission="{
                roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
                operator: '==='
              }"
            />
          </template>
        </v-col>
      </v-row>
    </v-form>

    <DialogPlanHistory
      v-if="dialog"
      :dialog="dialog"
      :title="dialogTitle"
      :history="dialogModeHistory"
      :planTableType="plan.planTableType"
      :hasEligibility="plan.hasEligibility"
      :customerId="customerId"
      :contractId="$route.params.id"
      @closeModal="closeModal"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { rulesMixin } from '@/mixins/rules';
import { checkPermission } from '@/helpers/permission';
import { formatMoney } from '@/helpers/formatting';
import { randomId } from '@/helpers/utils';
import { confirmMessage, showMessage } from '@/helpers/messages';
import { ContractService } from '@/services/api/contracts';

export default {
  mixins: [rulesMixin],

  components: {
    DialogPlanHistory: () => import('./dialogs/dialog-plan-history')
  },

  props: {
    productType: {
      type: String
    },
    customerId: {
      type: String
    },
    hasSub: {
      type: Boolean
    }
  },

  data: () => ({
    isEditable: false,
    valid: true,
    validList: true,
    dialog: false,
    dialogModeHistory: false,
    dialogTitle: '',
    plan: {
      planTableType: null,
      hasEligibility: false,
      HasPlanReadjustmentHistory: false,
      billingType: null,
      value: null,
      positions: null,
      plans: []
    }
  }),

  created() {
    this.search();
  },

  computed: {
    ...mapGetters({
      contract: 'contracts/getContractById'
    }),

    filteredHeaders() {
      const ageGroup = this.plan.planTableType === 'Faixa etária';

      let headers = [
        { text: 'Plano', value: 'planName', width: 300 },
        {
          text: 'Código do plano na operadora',
          value: 'externalPlanCode',
          width: ageGroup ? 200 : 'auto'
        },
        { text: 'Cargos', value: 'positions' },
        { text: 'Valor', value: 'value' },
        { text: '0-18', value: 'priceLives0To18', width: 150 },
        { text: '19-23', value: 'priceLives19To23', width: 150 },
        { text: '24-28', value: 'priceLives24To28', width: 150 },
        { text: '29-33', value: 'priceLives29To33', width: 150 },
        { text: '34-38', value: 'priceLives34To38', width: 150 },
        { text: '39-43', value: 'priceLives39To43', width: 150 },
        { text: '44-48', value: 'priceLives44To48', width: 150 },
        { text: '49-53', value: 'priceLives49To53', width: 150 },
        { text: '54-58', value: 'priceLives54To58', width: 150 },
        { text: '59-120', value: 'priceLives59To120', width: 150 }
      ];

      if (this.plan.planTableType === 'Faixa etária') {
        headers = headers.filter(
          header => header.value !== 'value' && header.value !== 'positions'
        );
      } else {
        headers = headers.filter(header => {
          return header.value.includes('priceLives') === false;
        });
      }

      if (!this.plan.hasEligibility) {
        headers = headers.filter(header => header.value !== 'positions');
      }

      if (
        checkPermission(
          ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
          '==='
        )
      ) {
        headers.unshift({ text: '', value: 'action' });
      }

      if (this.contract.situation === 'Cancelado') {
        headers = headers.filter(header => header.value !== 'action');
      }

      return headers;
    }
  },

  methods: {
    formatMoney,

    back() {
      this.$emit('back');
    },

    addPlan() {
      if (!this.$refs.form.validate(true)) return;

      let param = Object.assign({}, this.plan);
      let positions = param.positions && param.positions.toString();

      if (this.plan.planTableType === 'Faixa etária') {
        this.plan.plans.push({
          id: randomId(),
          insuranceCompanyPlanId: param.planSelected.id,
          planName: param.planSelected.businessPlanName,
          positions: positions,
          value: param.value,
          externalPlanCode: null,
          priceLives0To18: null,
          priceLives19To23: null,
          priceLives24To28: null,
          priceLives29To33: null,
          priceLives34To38: null,
          priceLives39To43: null,
          priceLives44To48: null,
          priceLives49To53: null,
          priceLives54To58: null,
          priceLives59To120: null,
          newItem: true
        });
      } else {
        this.plan.plans.push({
          id: randomId(),
          insuranceCompanyPlanId: param.planSelected.id,
          planName: param.planSelected.businessPlanName,
          positions: positions,
          value: param.value,
          externalPlanCode: null,
          newItem: true
        });
      }

      this.plan.planSelected = null;
      this.plan.positions = null;
      this.plan.value = null;
      this.$refs.form.resetValidation();
    },

    save() {
      if (!this.$refs.formList.validate(true)) {
        return;
      }

      if (this.plan.id) {
        this.update();
      } else {
        this.create();
      }
    },

    async search() {
      try {
        const contractService = new ContractService();
        const { status, data } = await contractService.getPlans({
          contractId: this.$route.params.id,
          customerId: this.customerId
        });

        if (status === 200) {
          this.plan = data;
          this.defaultFieldsValue(data);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async update() {
      try {
        const contractService = new ContractService();
        const { status } = await contractService.updatePlan({
          contractId: this.$route.params.id,
          customerId: this.customerId,
          ...this.plan
        });

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.search();
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async create() {
      try {
        let params = Object.assign({}, this.plan);

        delete params.planSelected;
        delete params.positions;
        delete params.value;

        params.plans.forEach(x => {
          delete x.planName;
          delete x.newItem;
          delete x.id;
        });

        const contractService = new ContractService();
        const { status } = await contractService.createPlan({
          contractId: this.$route.params.id,
          customerId: this.customerId,
          ...params
        });

        if (status === 200 || status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.search();
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async remove(item) {
      const result = await confirmMessage(
        'Atenção!',
        'Deseja realmente excluir o registro selecionado?'
      );

      if (!result) return;

      if (item.newItem) {
        let index = this.plan.plans.findIndex(x => x.id === item.id);

        if (index !== -1) {
          this.plan.plans.splice(index, 1);
        }
      } else {
        try {
          const contractService = new ContractService();
          const { status } = await contractService.deletePlan(item.id);

          if (status === 200 || status === 204) {
            showMessage('success', 'Operação realizada com sucesso');
            this.search();
          }
        } catch (error) {
          throw new Error(error);
        }
      }
    },

    defaultFieldsValue(result) {
      if (result.plans.length > 0) {
        this.isEditable = true;
      } else {
        this.isEditable = false;
      }

      setTimeout(() => {
        this.$refs.form.resetValidation();
      }, 200);
    },

    history(modeHistory) {
      this.dialog = true;

      if (modeHistory) {
        this.dialogTitle = 'Visualizar histórico dos planos';
        this.dialogModeHistory = true;
      } else {
        if (this.plan.plans.length === 0) {
          showMessage(
            'Error',
            `Para reajustar os valores dos planos, é preciso ter planos cadastrados!`
          );
          return;
        }

        this.dialogTitle = 'Reajustar percentual dos planos';
        this.dialogModeHistory = false;
      }
    },

    closeModal(search) {
      this.dialog = false;
      this.dialogTitle = '';
      this.dialogModeHistory = false;

      if (search) {
        this.search();
      }
    }
  }
};
</script>
